<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :options.sync="options"
    :server-items-length="totalLines"
    :footer-props="{
      showFirstLastPage: true,
      'items-per-page-options': [5, 10, 15],
    }"
    :hide-default-footer="hideTableFooter"
  >
    <template v-slot:top>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Product</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="OrderForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.sampleProductId"
                      label="Sample ProductId"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.sku" label="SKU" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.quantity"
                      label="Quantity"
                      :rules="[rules.number]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.picked"
                      label="Picked"
                      :rules="[rules.number]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.statusId"
                      :items="status_list"
                      item-text="statusInText"
                      item-value="id"
                      label="Status"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.instructions"
                      label="Instruction"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-chip
            class="ma-2"
            text-color="white"
            :v-if="!!item.status"
            :color="
              item.status && status_list.length > 0
                ? status_list.find((x) => x.id == item.status).code
                : ''
            "
            v-on="on"
          >
            {{
              item.status && status_list.length > 0
                ? status_list.find((x) => x.id == item.status).statusInText
                : ""
            }}
          </v-chip>
        </template>
        <span>{{ statusFullForm(!!item.status ? item.status : 0) }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn color="info" class="mr-2" fab small @click="pickItem(item)">
        <v-icon>mdi-alpha-p </v-icon>
      </v-btn>
    </template>
    <!-- <template v-slot:[`item.instructions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-chip text-color="white" color="primary" v-on="on">
              {{ showInstructions(item).total }}
            </v-chip>
          </span>
        </template>
        <span v-html="showInstructions(item).text" />
      </v-tooltip>
    </template> -->
    <!-- <template v-slot:[`item.pickedBy`]="{ item }">
      <div v-if="item.pickedProducts.length > 0">
        {{
          item.pickedProducts[0].pickedBy != null
            ? item.pickedProducts[0].pickedBy.userName
            : ""
        }}
      </div>
    </template> -->
    <template v-slot:[`item.pickedAt`]="{ item }">
      <div>
        {{ dateFormat(item.pickedAt) }}
      </div>
    </template>
    <!-- <template v-slot:[`item.actions`]="{ item }">
      <v-btn color="info" class="mr-2" fab small @click="editItem(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn color="error" fab small @click="deleteItem(item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template> -->
  </v-data-table>
</template>

<script>
import moment from "moment";
export default {
  props: {
    value: { type: Array, default: () => [] },
    boxId: String,
    zones: { type: Array, default: () => [] },
    status_list: { type: Array, default: () => [] },
    loading: Boolean,
    search_field: String,
    search_value: String,
    queue: String,
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Product ID",
        align: "start",
        value: "ean",
        sortable: false,
      },
      {
        text: "Bay",
        align: "center",
        value: "bayName",
        sortable: false,
      },
      { text: "SKU", value: "sku", sortable: false },
      {
        text: "Description",
        value: "description",
        sortable: false,
      },
      {
        text: "Quantity",
        align: "center",
        value: "quantity",
        sortable: false,
      },
      {
        text: "Picked",
        align: "center",
        value: "picked",
        sortable: false,
      },
      {
        text: "Status",
        align: "center",
        value: "status",
        sortable: false,
      },
      {
        text: "Replacement Product",
        value: "replacementProductEan",
        sortable: false,
      },
      // {
      //   text: "Instructions",
      //   value: "instructions",
      //   sortable: false,
      // },
      {
        text: "Picked By",
        value: "pickedBy",
        sortable: false,
      },
      {
        text: "Picked At",
        value: "pickedAt",
        sortable: false,
      },
    ],
    paginatedLines: [],
    totalLines: 0,
    options: {},
    permissions: localStorage.getItem("permissions"),
    rules: {
      required: (value) => !!value || "Required.",
      number: (value) =>
        Number.isInteger(Number(value)) || "The value must be an number.",
    },
    editedItem: {
      orderBoxId: "",
      sampleProductId: "",
      sku: "",
      description: "",
      quantity: "",
      picked: 0,
      statusId: 1,
      instructions: "",
    },
    defaultItem: {
      orderBoxId: "",
      sampleProductId: "",
      sku: "",
      description: "",
      quantity: "",
      picked: 0,
      statusId: 1,
      instructions: "",
    },
  }),
  computed: {
    hideTableFooter() {
      return this.search_value ? false : true;
    },
    items: {
      get() {
        if (!this.search_value) {
          this.totalLines = this.value.length;
          return this.value.sort((a, b) => {
            let A = a.bayName.split(" ")[1];
            let B = b.bayName.split(" ")[1];
            return A - B;
          });
        } else {
          return this.paginatedLines;
        }
      },
      set() {
        this.$emit("input", this.value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        if (!this.options.isSearch) {
          this.getOrderLines();
        }
      },
      deep: true,
    },
  },
  created() {
    this.$ordersHub.$on("status-changed", this.onStatusChanged);
  },
  beforeDestroy() {
    this.$ordersHub.$off("status-changed", this.onStatusChanged);
  },
  mounted() {
    if (this.queue) {
      this.headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
      });
    }
  },
  methods: {
    getOrderLines() {
      if (this.search_field === "OrderProductStatus") {
        this.search_field = "Status";
      }
      if (this.search_value) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let data = {
          pageNumber: page,
          pageSize: itemsPerPage,
          search: this.search_value,
          searchColumn: this.search_field,
        };
        this.$http
          .post("Orders/OrderLines/Paginated", data)
          .then((response) => {
            this.paginatedLines = response.data.data.items;
            this.totalLines = response.data.data.totalCount;
          });
      }
    },
    editItem(item) {
      item.orderId = this.boxId;
      this.$emit("editItem", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
    statusFullForm(id) {
      var status = "";
      switch (id) {
        case 1:
          status = "Waiting to be picked";
          break;
        case 2:
          status = "Partially picked";
          break;
        case 3:
          status = "Order/line picked";
          break;
        case 4:
          status = "Order passed Final Station";
          break;
        case 5:
          status = "Order completed at the warehouse";
          break;
        case 6:
          status = "Order dispatched from the warehouse";
          break;
        case 7:
          status = "Order delivered to the customer";
          break;
      }
      return status;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.$refs.OrderForm.validate()) {
        this.editedItem.orderBoxId = this.boxId;
        this.$emit("addSampleProduct", this.editedItem);
        this.close();
      }
    },
    showInstructions(item) {
      const instruction =
        (item.instruction1 !== null
          ? "Bay 1: " +
            item.instruction1.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction2 !== null
          ? "Bay 2: " +
            item.instruction2.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction3 !== null
          ? "Bay 3: " +
            item.instruction3.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction4 !== null
          ? "Bay 4: " +
            item.instruction4.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction5 !== null
          ? "Bay 5: " +
            item.instruction5.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction6 !== null
          ? "Bay 6: " +
            item.instruction6.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction7 !== null
          ? "Bay 7: " +
            item.instruction7.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction8 !== null
          ? "Bay 8: " +
            item.instruction8.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction9 !== null
          ? "Bay 9: " +
            item.instruction9.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction10 !== null
          ? "Bay 10: " +
            item.instruction10.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction11 !== null
          ? "Bay 11: " +
            item.instruction11.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction12 !== null
          ? "Bay 12: " +
            item.instruction12.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction13 !== null
          ? "Bay 13: " +
            item.instruction13.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction14 !== null
          ? "Bay 14: " +
            item.instruction14.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "") +
        (item.instruction15 !== null
          ? "Bay 15: " +
            item.instruction15.replace(";", "<br>&emsp;&emsp;&emsp;") +
            "<br>"
          : "");
      const count = instruction.split("Bay").length - 1;

      return {
        text: instruction,
        total: count,
      };
    },
    pickItem(item) {
      this.$http
        .post("OrderProcess/Pick", {
          queueName: this.queue,
          orderLineId: item.orderLineId,
          quantity: 1,
          productType: 1,
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success("Picked successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
    },
    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },
    onStatusChanged(data) {
      if (data.Status === 4) {
        let products = this.items.filter((x) => x.orderBoxId === data.BoxId);
        if (products && products.length > 0) {
          products.forEach((product) => {
            let index = this.items.findIndex(
              (x) => x.orderLineId === product.orderLineId
            );
            if (index > -1) {
              let obj = this.items[index];
              obj.status = data.Status;
              obj.pickedAt = data.createdAt;
              this.items.splice(index, 1, obj);
            }
          });
        }
      } else {
        let index = this.items.findIndex((x) => x.orderLineId === data.LineId);
        if (index > -1) {
          let obj = this.items[index];
          obj.picked = obj.picked + data.Quantity;
          obj.status = data.Status;

          this.items.splice(index, 1, obj);
        }
      }
    },
  },
};
</script>

<style>
</style>
