<template>
  <div style="display: inline">
    <v-icon
      v-if="onlyIcon"
      :color="color ? color : 'primary'"
      style="cursor: pointer"
      @click="dialog = true"
      >{{ icon }}
    </v-icon>
    <v-btn v-else :color="color ? color : 'primary'" @click="dialog = true">
      <v-icon>{{ icon }} </v-icon>
      <div v-if="label" class="ml-1">{{ label }}</div>
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title
          class="headline"
          v-html="message"
          style="word-break: normal"
        >
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close">
            {{ negativeBtn || "Cancel" }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="ok">
            {{ positiveBtn || "Ok" }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Prompt",
  props: [
    "message",
    "positiveBtn",
    "negativeBtn",
    "color",
    "label",
    "icon",
    "onlyIcon",
  ],
  data: () => ({
    dialog: false,
  }),
  methods: {
    close() {
      this.dialog = false;
    },
    ok() {
      this.dialog = false;
      this.$emit("click");
    },
  },
};
</script>