<template>
  <v-container id="dashboard-view" fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#9de35c"
      loader="bars"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />

    <v-col cols="12">
      <material-card
        color="primary"
        icon="mdi-earth"
        icon-small
        inline
        :title="
          'Pick Status Analytics   --    ' +
          'In memory: ' +
          downloadedOrderProducts +
          '  In db: ' +
          serverOrderProducts
        "
      >
        <v-row class="ma-0">
          <v-col cols="12" md="6" class="mt-10">
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Bulk status update</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-col cols="12">
                      <v-select
                        v-model="statusId"
                        :items="status_list"
                        item-text="statusInText"
                        item-value="id"
                        label="Status"
                      />
                    </v-col>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="bulkStatusUpdate">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCsvShiftStatus" max-width="500px">
              <v-card>
                <v-card-title
                  class="headline"
                  v-html="dialogCsvShiftStatusMessage"
                >
                </v-card-title>
                <v-card-text>
                  <v-autocomplete
                    v-if="!currentCsv.shiftOn"
                    v-model="selectedQueue"
                    :items="queues"
                    label="Queue"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogCsvShiftStatus = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="updateShiftStatus(currentCsv)"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCsvFullReport" max-width="500px">
              <v-card>
                <v-card-title class="headline"> Full report </v-card-title>
                <v-card-text>
                  <v-autocomplete
                    v-model="currentZone"
                    :items="zones"
                    item-text="name"
                    item-value="id"
                    label="Bay"
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogCsvFullReport = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click.native="
                      $router.push({
                        path: '/csv-orders',
                        query: { csv: currentCsvId, zone: currentZone },
                      })
                    "
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogTransfer" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  {{ dialogTransferText }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogTransfer = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="transferOrders()">
                    Migrate
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="dialogAnalytics" max-width="720px">
              <v-card>
                <v-card-title>
                  <span class="headline"></span>
                </v-card-title>
                <v-card-text>
                  <h3>Start Date Time:</h3>
                  <v-row>
                    <v-col>
                      <v-date-picker v-model="startDate"></v-date-picker>
                    </v-col>
                    <v-col>
                      <v-time-picker
                        format="ampm"
                        v-model="startTime"
                      ></v-time-picker>
                    </v-col>
                  </v-row>
                  <h3>End Date Time:</h3>
                  <v-row>
                    <v-col>
                      <v-date-picker v-model="endDate"></v-date-picker>
                    </v-col>
                    <v-col>
                      <v-time-picker
                        format="ampm"
                        v-model="endTime"
                      ></v-time-picker>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogAnalytics = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="getAnalytics">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
            <v-data-table
              :headers="headers"
              :items="orderAnalysis"
              :options.sync="options"
              :server-items-length="totalCsv"
              :loading="loading"
              :items-per-page="5"
              single-expand
              :expanded.sync="expanded"
              item-key="id"
              show-expand
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15],
              }"
            >
              <template v-slot:[`item.created`]="{ item }">
                <div>{{ dateFormat(item.created) }}</div>
              </template>
              <template v-slot:[`item.tableLocation`]="{ item }">
                <v-chip
                  class="ma-2"
                  text-color="white"
                  :v-if="!!item.tableLocation"
                  color="primary"
                >
                  {{
                    tableLocations.find((x) => x.value == item.tableLocation)
                      .text
                  }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                      class="mr-2"
                      @click="fullReport(item.id)"
                      >mdi-chart-box</v-icon
                    >
                  </template>
                  <span>Full report.</span>
                </v-tooltip> -->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="warning"
                      class="mr-2"
                      @click.native="
                        $router.push({
                          path: '/picking-report',
                          query: { csvId: item.id },
                        })
                      "
                      >mdi-chart-box</v-icon
                    >
                  </template>
                  <span>Picking report.</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="warning"
                      class="mr-2"
                      @click.native="
                        $router.push({
                          path: '/csvErrors',
                          query: { csvId: item.id },
                        })
                      "
                      >mdi-text-box-outline</v-icon
                    >
                  </template>
                  <span>Logs.</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      medium
                      :color="item.shiftOn ? 'secondary' : 'error'"
                      class="mr-2"
                      @click="
                        (dialogCsvShiftStatus = true),
                          (currentCsv = item),
                          (dialogCsvShiftStatusMessage = `Do you want to ${
                            item.shiftOn ? ' disable ' : ' enable '
                          } ${item.totalOrders} records from <br> ${
                            item.csvName
                          } for download?`)
                      "
                      >mdi-account-hard-hat</v-icon
                    >
                  </template>
                  <span
                    >{{ item.csvShiftStatus ? " Disable " : " Enable " }} the
                    CSV file for picking.</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      medium
                      color="info"
                      class="mr-2"
                      @click="openDialogTransfer(item)"
                    >
                      mdi-transfer-right
                    </v-icon>
                  </template>
                  <span
                    >Move all records from the CSV file to the secondary
                    table.</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="updateStatusByCsv"
                      medium
                      color="info"
                      class="mr-2"
                      @click="update(item.id)"
                    >
                      mdi-update
                    </v-icon>
                  </template>
                  <span
                    >Update the picking status of all records in the CSV
                    file.</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <prompt
                      icon="mdi-delete"
                      :onlyIcon="true"
                      :message="`Are you sure you want to delete this ${item.csvName}?`"
                      positiveBtn="Yes"
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                      @click="
                        currentCsv = item;
                        transferAndDeleteOrders();
                      "
                    />
                    <!-- <v-icon
                      v-bind="attrs"
                      v-on="on"
                      medium
                      color="error"
                      class="mr-2"
                      @click="openDialogTransfer(item)"
                    >
                      mdi-delete
                    </v-icon> -->
                  </template>
                  <span>Delete all records relating to the CSV file.</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="isSuperUser"
                      medium
                      color="info"
                      class="mr-2"
                      @click="downloadNotPicked(item.id)"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download not picked records in the CSV file.</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="isSuperUser"
                      medium
                      color="info"
                      class="mr-2"
                      @click="downloadCsv(item.csvName)"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download the CSV file.</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="primary pa-1">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Total</th>
                          <th>Waiting</th>
                          <th>Partially Picked</th>
                          <th>Picked</th>
                          <th>Processed</th>
                          <th>Queue</th>
                          <th>Table</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ item.totalOrders }}</td>
                          <td>
                            {{ item.wait }}
                          </td>
                          <td>{{ item.ppkd }}</td>
                          <td>{{ item.pikd }}</td>
                          <td>{{ item.prcs }}</td>
                          <td>{{ item.queueName }}</td>
                          <td>
                            <v-chip
                              class="ma-2"
                              text-color="white"
                              :v-if="!!item.tableLocation"
                              color="primary"
                            >
                              {{
                                tableLocations.find(
                                  (x) => x.value == item.tableLocation
                                ).text
                              }}
                            </v-chip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" md="6">
            <v-responsive max-height="375">
              <v-btn
                fab
                dark
                x-large
                color="#ffef3a"
                style="top: 48%; left: 60%"
              >
                <img src="../assets/trailer.png" alt="" width="50" />
              </v-btn>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.11880413259!2d150.966926815031!3d-33.860828980658574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bd70570db12f%3A0x4e36fdda82a887d2!2s36%20Loftus%20Rd%2C%20Yennora%20NSW%202161%2C%20Australia!5e0!3m2!1sen!2slk!4v1666612133037!5m2!1sen!2slk"
                width="850"
                height="350"
                style="border: 0; margin-top: -25px"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </v-responsive>
          </v-col>

          <v-col cols="12" md="6">
            <v-row justify="space-between">
              <h3 class="ml-1" style="color: #3a4b67">
                Unknown barcodes scanned
              </h3>
              <prompt
                icon="mdi-close"
                label="Clear"
                :message="`Are you sure you want to clear the unknown barcode list?`"
                positiveBtn="Yes"
                color="error"
                @click="clearUnknownBarcode"
              />
            </v-row>
            <v-data-table
              :headers="unknownBarCodesHeaders"
              :items="unknownBarCodes"
              :options.sync="unknownBarCodesOptions"
              :server-items-length="totalUnknownBarCodes"
              :loading="unknownBarCodesLoading"
              :items-per-page="5"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15],
              }"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ dateFormat(item.createdAt) }}
              </template>
            </v-data-table>
            <!-- <v-responsive
              max-height="325px"
              class="d-flex align-center overflow-y-auto"
            >
              <h3 class="ml-1" style="color: #3a4b67">
                Unknown barcodes scanned
              </h3>
              <v-card v-for="(item, i) in unknownBarCodes" :key="i" class="my-1"
                ><v-card-text>{{
                  "Queue: " +
                  item.queueName +
                  "Bay: " +
                  item.bayName +
                  "Box: " +
                  item.boxId +
                  ",  Barcode: " +
                  item.barcode +
                  ", ScannedAt: " +
                  dateFormat(item.createdAt)
                }}</v-card-text>
              </v-card>
              <v-card v-intersect="onIntersect">
                <v-card-text></v-card-text>
              </v-card>
            </v-responsive> -->
          </v-col>

          <v-col cols="12" md="6">
            <h3 class="ml-1" style="color: #3a4b67">
              Order lines count by Bay ID
            </h3>
            <v-data-table
              :headers="bayOrderLinesDownloadHeaders"
              :items="bayOrderLinesDownload"
              :loading="bayOrderLinesDownloadLoading"
              :items-per-page="5"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15],
              }"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ dateFormat(item.createdAt) }}
              </template>
            </v-data-table>
            <!-- <v-responsive
              max-height="350px"
              class="d-flex align-center overflow-y-auto"
            >
              <h3 class="ml-1" style="color: #3a4b67">
                Order lines count by Bay ID
              </h3>
              <v-card
                v-for="(item, i) in bayOrderLinesDownload"
                :key="i"
                class="my-1"
                ><v-card-text>{{
                  "Queue: " +
                  item.queueName +
                  ", " +
                  item.bayName +
                  ", OrdLns: " +
                  item.quantity +
                  ", DownloadedAt: " +
                  dateFormat(item.createdAt)
                }}</v-card-text>
              </v-card>
              <v-card v-intersect="onIntersect">
                <v-card-text></v-card-text>
              </v-card>
            </v-responsive> -->
          </v-col>

          <v-col cols="12">
            <h3 class="ml-1" style="color: #3a4b67">Picking queue</h3>

            <v-row class="pr-5">
              <v-col>
                <v-autocomplete
                  v-model="pickingQueue"
                  :items="queues"
                  label="Queue"
                  style="width: 250px"
                  class="text-right"
                />
              </v-col>

              <prompt
                icon="mdi-close"
                label="Clear"
                :message="`Are you sure you want to clear the picking queue?`"
                positiveBtn="Yes"
                color="error"
                @click="clearPickingQueue"
            /></v-row>

            <BoxTable
              v-model="filteredBoxQueue"
              :headers="[
                { text: 'Box Number', value: 'orderBoxId' },
                { text: 'Queue', value: 'queueName' },
                { text: 'Scanned At', value: 'createdAt' },
              ]"
              :status_list="status_list"
              :queue="pickingQueue"
            />
          </v-col>
        </v-row>
      </material-card>
    </v-col>
    <!-- <v-row class="ml-2">
      <v-col cols="4" md="2">
        <v-dialog
          ref="startTimeDialog"
          v-model="startTimeDialog"
          :return-value.sync="startTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startTime"
              label="Start Time"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker v-if="startTimeDialog" v-model="startTime" full-width>
            <v-spacer />
            <v-btn text color="primary" @click="startTimeDialog = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="pickTime(startTime)">
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
      <v-col cols="4" md="2">
        <v-dialog
          ref="startDateDialog"
          v-model="startDateDialog"
          :return-value.sync="startDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="startDate" scrollable>
            <v-spacer />
            <v-btn text color="primary" @click="startDateDialog = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="pickDate(startDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row> -->
    <!-- <v-row class="ml-2">
      <v-col cols="4" md="2">
        <v-dialog
          ref="endTimeDialog"
          v-model="endTimeDialog"
          :return-value.sync="endTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endTime"
              label="End Time"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker v-if="endTimeDialog" v-model="endTime" full-width>
            <v-spacer />
            <v-btn text color="primary" @click="endTimeDialog = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.endTimeDialog.save(endTime)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
      <v-col cols="4" md="2">
        <v-dialog
          ref="endDateDialog"
          v-model="endDateDialog"
          :return-value.sync="endDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="endDate" scrollable>
            <v-spacer />
            <v-btn text color="primary" @click="endDateDialog = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.endDateDialog.save(endDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row> -->
    <!-- 
    <v-col cols="12">
      <v-row v-if="canViewGetByFilter">
        <v-col
          v-for="({ actionIcon, ...attrs }, i) in stats"
          :key="i"
          cols="12"
          md="6"
          lg="3"
        >
          <material-stat-card v-bind="attrs">
            <template #actions>
              <v-icon class="mr-2" small v-text="actionIcon" />
              <div class="text-truncate">
                {{ getAnalyticsDateTime }}
              </div>
            </template>
          </material-stat-card>
        </v-col>
      </v-row>
    </v-col> -->
  </v-container>
</template>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Prompt from "./components/Prompt.vue";
import BoxTable from "./components/OrderBoxesTableFromQueue.vue";

const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0,
});

export default {
  name: "DashboardView",
  components: { Loading, Prompt, BoxTable },
  data: () => ({
    isLoading: false,
    loadingSize: 128,
    loadingFullPage: false,
    downloadedOrderProducts: 0,
    serverOrderProducts: 0,
    startTimeDialog: false,
    endTimeDialog: false,
    startDateDialog: false,
    endDateDialog: false,
    dialog: false,
    dialogTransfer: false,
    dialogCsvFullReport: false,
    dialogCsvShiftStatusMessage: "",
    dialogCsvShiftStatus: false,
    expanded: [],
    currentCsv: {},
    dialogTransferText: "Are you sure you want to migrate orders",
    options: {},
    totalCsv: 0,
    loading: false,
    csvCreated: "",
    currentTableLocation: 0,
    dialogAnalytics: false,
    permissions: localStorage.getItem("permissions"),
    startTime: "",
    startDate: "",
    endTime: "",
    endDate: "",
    headers: [
      { text: "Csv Name", value: "csvName" },
      { text: "Created At", value: "created" },
      { text: "Actions", align: "center", value: "actions" },
    ],
    zones: [],
    tableLocations: [
      { text: "Primary", value: 0 },
      { text: "Secondary", value: 1 },
    ],
    status_list: [],
    statusId: "",
    currentCsvId: "",
    currentCsv: {},
    currentZone: 0,
    stats: [
      {
        actionIcon: "mdi-calendar-range",
        actionText: "Last 24 Hours",
        color: "#f70762",
        icon: "mdi-chart-bar",
        title: "New Orders",
        value: "",
      },
      {
        actionIcon: "mdi-calendar-range",
        actionText: "Last 24 Hours",
        color: "#fe6609",
        icon: "mdi-chart-bar",
        title: "Partially Picked",
        value: "",
      },
      {
        actionIcon: "mdi-calendar-range",
        actionText: "Last 24 Hours",
        color: "#37c50c",
        icon: "mdi-chart-bar",
        title: "Picked",
        value: "",
      },
      {
        actionIcon: "mdi-calendar-range",
        actionText: "Last 24 Hours",
        color: "#0db8fc",
        icon: "mdi-chart-bar",
        title: "Processed",
        value: "",
      },
    ],
    orderAnalysis: [],
    last24Hours: {},
    awaitingSearch: false,
    queues: [],
    selectedQueue: "",

    unknownBarCodesHeaders: [
      { text: "Queue", value: "queueName" },
      { text: "Barcode", value: "barcode" },
      { text: "Bay", value: "bayName" },
      { text: "Box", value: "boxId" },
      { text: "Created at", value: "createdAt" },
    ],
    unknownBarCodes: [],
    unknownBarCodesOptions: {},
    totalUnknownBarCodes: 0,
    unknownBarCodesLoading: false,

    bayOrderLinesDownload: [],
    bayOrderLinesDownloadHeaders: [
      { text: "Queue", value: "queueName" },
      { text: "Barcode", value: "barcode" },
      { text: "Bay", value: "bayName" },
      { text: "Box", value: "boxId" },
      { text: "Created at", value: "createdAt" },
    ],

    bayOrderLinesDownloadLoading: false,

    BoxQueue: [],
    status_list: [],
    pickingQueue: "",
  }),
  watch: {
    options: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    unknownBarCodesOptions: {
      handler() {
        this.getBarCodeLogs();
      },
      deep: true,
    },
    getAnalyticsDateTime: {
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getAnalytics();
            this.awaitingSearch = false;
          }, 1500);
        }
        this.awaitingSearch = true;
      },
    },
  },

  created() {
    this.$ordersHub.$on("new-barcode-log", this.onNewBarcodeLog);
    this.$ordersHub.$on(
      "notify-downloaded-order-lines",
      this.onNotifyDownloadedOrderLines
    );
    this.$ordersHub.$on("box-queued", this.onBoxQueued);

    this.endTime = moment().format("hh:mm");
    this.endDate = moment().format("YYYY-MM-DD");

    this.startTime =
      localStorage.getItem("startTime") ||
      moment().add(-7, "days").format("hh:mm");
    this.startDate =
      localStorage.getItem("startDate") ||
      moment().add(-7, "days").format("YYYY-MM-DD");
    this.initialize();

    this.$http.get("Queues").then((response) => {
      this.queues = response.data;
      this.selectedQueue =
        this.queues && this.queues.length > 0 ? this.queues[0] : "";
      this.pickingQueue =
        this.queues && this.queues.length > 0 ? this.queues[0] : "";
    });

    this.$http.get("Statuses").then((response) => {
      this.status_list = response.data;
    });

    this.$http.get("Queues/PickingQueue/Boxes").then((response) => {
      if (response.data.data) {
        let data = response.data.data.map((x) => {
          return { queueName: x.item2, orderBoxId: x.item1 };
        });
        this.BoxQueue = data ? data : [];
      }
    });
    this.getBayOrderLinesDownloads();
  },

  beforeDestroy() {
    this.$ordersHub.$off("new-barcode-log", this.onNewBarcodeLog);
    this.$ordersHub.$off(
      "notify-downloaded-order-lines",
      this.onNotifyDownloadedOrderLines
    );
    this.$ordersHub.$off("box-queued", this.onBoxQueued);
  },
  computed: {
    filteredBoxQueue() {
      if (this.pickingQueue) {
        let boxes = this.BoxQueue.filter(
          (x) => x.queueName === this.pickingQueue
        );

        return boxes;
      }
    },
    getAnalyticsDateTime() {
      return (
        this.startTime +
        " " +
        this.startDate +
        " to " +
        this.endTime +
        " " +
        this.endDate
      );
    },
    totalSales() {
      return this.sales.reduce((acc, val) => acc + val.salesInM, 0);
    },
    canViewLast24Hours() {
      return this.permissions.includes(
        "Permissions.OrderAnalysis.GetLast24Hours"
      );
    },
    canViewGetByFilter() {
      return this.permissions.includes("Permissions.OrderAnalysis.GetByFilter");
    },
    canViewCsvHistories() {
      return this.permissions.includes(
        "Permissions.OrderAnalysis.GetAllCsvHistories"
      );
    },
    updateStatusByCsv() {
      return this.permissions.includes(
        "Permissions.OrderAnalysis.UpdateStatusByCSVOrders"
      );
    },
    isSuperUser() {
      return this.$store.state.authUser.username === "SuperUser";
    },
  },
  methods: {
    getBarCodeLogs() {
      this.unknownBarCodesLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } =
        this.unknownBarCodesOptions;
      this.$http
        .post("Queues/BarcodeLogs", {
          pageNumber: page,
          pageSize: itemsPerPage,
        })
        .then((response) => {
          this.unknownBarCodes = response.data.items;
          this.totalUnknownBarCodes = response.data.totalCount;
          this.unknownBarCodesLoading = false;
        })
        .catch(() => {
          this.unknownBarCodesLoading = false;
        });
    },
    getBayOrderLinesDownloads() {
      this.bayOrderLinesDownloadLoading = true;

      this.$http
        .get("Queues/DeviceDownloads")
        .then((response) => {
          this.bayOrderLinesDownload = response.data.data;
          this.bayOrderLinesDownloadLoading = false;
        })
        .catch(() => {
          this.bayOrderLinesDownloadLoading = false;
        });
    },

    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },
    initialize() {
      this.$http.get("/Bays").then((response) => {
        this.zones = response.data;
      });
      this.updateOrderProductsCount();
    },
    updateOrderProductsCount() {
      this.$http.get("OrderLines/InMemory/Count").then((response) => {
        this.downloadedOrderProducts = response.data;
      });

      this.$http.get("OrderLines/InServer/Count").then((response) => {
        this.serverOrderProducts = response.data.data;
      });
    },
    getAll() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        pageNumber: page,
        pageSize: itemsPerPage,
      };

      this.$http.post("Shifts/Search", data).then((response) => {
        this.orderAnalysis = response.data.data.items;
        this.totalCsv = response.data.data.totalCount;
        this.loading = false;
      });
    },

    getAnalytics() {
      var data = {
        From: this.startDate + " " + this.startTime,
        To: this.endDate + " " + this.endTime,
      };
      // AnalysisDataService.getByFilter(data).then((response) => {
      //   if (response.status == 200) {
      //     this.$toast.success("Analytics date/time range updated.", "Success", {
      //       position: "topRight",
      //     });
      //   }
      //   this.last24Hours = response.data;
      //   var orderIndex = this.stats.findIndex((x) => x.title === "New Orders");
      //   this.$set(
      //     this.stats[orderIndex],
      //     "value",
      //     this.last24Hours.orders.toString()
      //   );
      //   var ppkdIndex = this.stats.findIndex(
      //     (x) => x.title === "Partially Picked"
      //   );
      //   this.$set(
      //     this.stats[ppkdIndex],
      //     "value",
      //     this.last24Hours.partiallyPicked.toString()
      //   );
      //   var pikdIndex = this.stats.findIndex((x) => x.title === "Picked");
      //   this.$set(
      //     this.stats[pikdIndex],
      //     "value",
      //     this.last24Hours.picked.toString()
      //   );
      //   var prcsIndex = this.stats.findIndex((x) => x.title === "Processed");
      //   this.$set(
      //     this.stats[prcsIndex],
      //     "value",
      //     this.last24Hours.processed.toString()
      //   );
      // });
      this.dialogAnalytics = false;
    },
    fullReport(id) {
      this.currentCsvId = id;
      this.dialogCsvFullReport = true;
    },
    update(id) {
      this.currentCsvId = id;
      this.dialog = true;
    },
    bulkStatusUpdate() {
      AnalysisDataService.updateStatusByCsvOrders(
        this.currentCsvId,
        this.statusId
      ).then((response) => {
        if (response.status === 200) {
          this.$toast.success("Status updated successfully.", "Success", {
            position: "topRight",
          });
        } else {
          this.$toast.error(
            "Something went wrong while updating status.",
            "Error",
            {
              position: "topRight",
            }
          );
        }
      });
      this.dialog = false;
    },
    pickTime(startTime) {
      this.$refs.startTimeDialog.save(startTime);
      localStorage.setItem("startTime", startTime);
    },
    pickDate(startDate) {
      this.$refs.startDateDialog.save(startDate);
      localStorage.setItem("startDate", startDate);
    },
    openDialogTransfer(item) {
      this.dialogTransfer = true;
      this.currentCsvId = item.id;
      this.currentCsv = item;
      this.currentTableLocation = item.tableLocation;
      this.dialogTransferText = "Are you sure you want to migrate orders ";

      if (this.currentTableLocation == 1) {
        this.dialogTransferText =
          this.dialogTransferText + "Secondary Table to Primary Table?";
      } else {
        this.dialogTransferText =
          this.dialogTransferText + "Primary Table to Secondary Table?";
      }
    },
    transferOrders() {
      if (this.currentTableLocation == 1) {
      } else {
        this.$http
          .get(`Shifts/Archive/${this.currentCsv.id}`)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
              this.getAll();
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          });
      }
      this.dialogTransfer = false;
    },
    transferAndDeleteOrders() {
      if (this.currentTableLocation == 1) {
      } else {
        this.$http
          .get(`Shifts/Archive/${this.currentCsv.id}`)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
              this.deleteCsv(this.currentCsv);
            } else {
              this.$toast.error(response.data.message, "Success", {
                position: "topRight",
              });
            }
          });
      }
      this.dialogTransfer = false;
    },
    deleteCsv(item) {
      this.$http.delete(`Shifts/${item.id}`).then((response) => {
        if (response.data.success) {
          this.getAll();
          this.$toast.success(response.data.message, "Success", {
            position: "topRight",
          });
        } else {
          this.$toast.error(response.data.message, "Error", {
            position: "topRight",
          });
        }
      });
    },
    updateShiftStatus(item) {
      this.isLoading = true;
      let data = {
        id: item.id,
        queueName: this.selectedQueue,
      };
      this.$http
        .post("Shifts/switch", data)
        .then((response) => {
          if (response.data.success) {
            let index = this.orderAnalysis.findIndex((x) => x.id === item.id);
            item.shiftOn = !item.shiftOn;
            this.orderAnalysis.splice(index, 1, item);
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
          this.isLoading = false;
          this.updateOrderProductsCount();
        })
        .catch((e) => {
          this.isLoading = false;
          this.updateOrderProductsCount();
        });
      this.dialogCsvShiftStatus = false;
    },
    onNewBarcodeLog(data) {
      this.unknownBarCodes.unshift(data);
    },
    onNotifyDownloadedOrderLines(data) {
      this.bayOrderLinesDownload.push(data);
    },
    onBoxQueued(data) {
      let itemsPerPage = 100;
      if (data.add) {
        this.BoxQueue.unshift(data);
        let newList = [];
        this.queues.forEach((queue) => {
          let boxes = this.BoxQueue.filter((x) => x.queueName === queue);
          if (boxes && boxes.length > itemsPerPage) {
            let filteredBoxes = this.BoxQueue.slice(0, itemsPerPage);
            if (filteredBoxes && filteredBoxes.length > 0) {
              newList = [...newList, ...filteredBoxes];
            }
          } else {
            newList = [...newList, ...boxes];
          }
        });

        this.BoxQueue = newList;
      } else {
        let index = this.BoxQueue.findIndex(
          (x) => x.orderBoxId === data.orderBoxId
        );
        if (index > -1) {
          this.BoxQueue.splice(index, 1);
        }
      }
    },
    downloadNotPicked(id) {
      this.$http
        .get(`/Shifts/DownlaodNotPicked/${id}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.downloadFile(
            response.data,
            "myOrders_NotPicked_CSV_" +
              this.$store.state.authUser.username +
              "_" +
              moment().format("h:mm:ss a DD.MM.YYYY") +
              ".csv"
          );
        });
    },
    downloadCsv(name) {
      this.$http
        .get(
          `${this.$http.defaults.baseURL.replace(
            "api/",
            ""
          )}Resources/Csv/${name}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.downloadFile(response.data, name);
        });
    },
    downloadFile(blob, fileName) {
      var fileUrl = window.URL.createObjectURL(new Blob([blob]));
      var fileLink = document.createElement("a");
      fileLink.href = fileUrl;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    clearPickingQueue() {
      this.$http
        .get("Queues/PickingQueue/Clear")
        .then(() => {
          this.BoxQueue = [];
          this.$toast.success(
            "Picking Queue cleared successfully.",
            "Success",
            {
              position: "topRight",
            }
          );
        })
        .catch(() => {
          this.$toast.success(
            "Something went wrong while clearing the picking queue",
            "Error",
            {
              position: "topRight",
            }
          );
        });
    },
    clearUnknownBarcode() {
      this.$http
        .get("Queues/BarcodeLogs/Clear")
        .then(() => {
          this.unknownBarCodes = [];
          this.$toast.success(
            "Unknown barcode list cleared successfully.",
            "Success",
            {
              position: "topRight",
            }
          );
        })
        .catch(() => {
          this.$toast.success(
            "Something went wrong while clearing the unknown barcode list",
            "Error",
            {
              position: "topRight",
            }
          );
        });
    },
  },
};
</script>
